<c-header-nav class="ms-3 user-menu-container">

    <c-dropdown alignment="end" variant="nav-item">
        <button cButton color="" [caret]="false" cDropdownToggle class="py-0 dropdown-trigger">
            <c-avatar class="avatar-icon" size="lg" status="success">
                {{initials}}
            </c-avatar>
        </button>
        <div cDropdownMenu id="user-dropdown">
            <div class="top-row">
                <a (click)="logout()">
                  {{'userMenu.signOut' | translate}}
                </a>
            </div>
            <div class="user-row">
                <div class="avatar-icon initials" >
                    {{initials}}
                </div>
                <div class="username">
                    {{displayName}}
                </div>
                <div class="email">
                    {{email}}
                </div>
            </div>
            <div class="menulink-section">
                <div class="menulink-element" [routerLink]="['/account/info']" routerLinkActive="active">
                  <span>{{'userMenu.manageYourAccount' | translate}}</span>
                </div>
            </div>
            <hr cDropdownDivider />
            <div class="link-section">
                <div class="link-element terms-element">
                    <a href="https://utimaco.com/terms-conditions">
                      <span>{{'userMenu.termsOfUse' | translate}}</span>
                    </a>
                </div>
                <div class="link-element privacy-element">
                    <a href="https://utimaco.com/privacy-statement-lan-crypt">
                      <span>{{'userMenu.privacy' | translate}}</span>
                    </a>
                </div>
            </div>
        </div>
    </c-dropdown>
</c-header-nav>
