import {Component, OnInit} from '@angular/core';
import {
  GroupService,
  GroupTreeViewDto,
  IdentityProviderConnectionService,
  IdentityProviderConnectionViewDto,
  UserTreeViewDto,
  IdentityProviderConnectionUsersDto,
  FieldErrorDto,
} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {ApiClientFactoryService} from '../../../../../services/apiclient-factory.service';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {GroupTreeViewFlatNode} from '../../../../../dtos/lancrypt/GroupTreeViewFlatNode';
import {JwtHelperService} from '../../../../../services/helper/jwt-helper.service';
import {ToastService} from '../../../../../services/toaster.service';
import {TranslateService} from '@ngx-translate/core';
import {FlatTreeControl} from '@angular/cdk/tree';
import {GroupTreeViewIconDto, SubFolderType} from '../../../../../dtos/lancrypt/GroupTreeViewIconDto';
import {GroupTreeService, GroupTreeTrait} from '../../../../../services/group-tree.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {DualOptionDialog} from '../../../../../shared/components/dual-option-dialog/dual-option-dialog.component';
import {MatDialog} from '@angular/material/dialog';
import {MatCheckboxChange} from '@angular/material/checkbox';
import {SelectionModel} from '@angular/cdk/collections';
import {BusyService} from '../../../../..//shared/components/busy-service/busy.service';

@Component({
  selector: 'app-connection-import',
  templateUrl: './connection-import.component.html',
  styleUrls: ['./connection-import.component.scss'],
})
export class ConnectionImportComponent implements OnInit {
  private userPageSize = 100;

  private tenantId = '';
  private connectionId = '';
  private skipToken: string | undefined = undefined;
  private loadMoreUsersClicked = false;

  private groupApiClient: GroupService;
  private connectionService: IdentityProviderConnectionService;

  hasChild = (_: number, node: GroupTreeViewFlatNode) => node.expandable;
  isLoadMoreUsers = (_: number, node: GroupTreeViewFlatNode) => node.subType === SubFolderType.LoadMoreUsers;
  hasLoadingError = false;
  loadingErrorMessage = '';

  private _transformer = (node: GroupTreeViewIconDto, level: number): GroupTreeViewFlatNode => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      level: level,
      id: node.id,
      icon: node.icon,
      subType: node.subType,
      groupName: node.groupName,
      childrenAmount: node.children.length,
      synced: node.syncedGroup,
      identityProviderConnectionId: node.identityProviderConnectionId,
    };
  };

  localTreeControl = new FlatTreeControl<GroupTreeViewFlatNode>(
    node => node.level,
    node => node.expandable
  );
  connectionTreeControl = new FlatTreeControl<GroupTreeViewFlatNode>(
    node => node.level,
    node => node.expandable
  );

  treeFlattener = new MatTreeFlattener(
    this._transformer,
    node => node.level,
    node => node.expandable,
    node => node.children
  );
  localViewDataSource = new MatTreeFlatDataSource(this.localTreeControl, this.treeFlattener);
  connectionDataSource = new MatTreeFlatDataSource(this.connectionTreeControl, this.treeFlattener, []);
  subtypes = SubFolderType;

  checklistSelection = new SelectionModel<string>(true);

  isSyncInProgress = false;

  constructor(
    apiClientFactory: ApiClientFactoryService,
    private jwtHelperService: JwtHelperService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private groupTreeService: GroupTreeService,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router,
    private busyService: BusyService
  ) {
    this.groupApiClient = apiClientFactory.getGroupService();
    this.connectionService = apiClientFactory.getIdentityProviderConnectionService();
  }

  ngOnInit(): void {
    this.jwtHelperService.getTenantIdFromToken().then(tenantId => {
      this.tenantId = tenantId;
      this.route.params.subscribe((params: Params) => {
        this.connectionId = params['connectionId'];

        if (!this.connectionId) {
          this.connectionService
            .getConnectionList(this.tenantId)
            .subscribe((connections: IdentityProviderConnectionViewDto[]) => {
              if (connections && connections.length > 0) {
                this.router.navigate(['lancrypt', 'connections', 'import', connections[0].id]);
              } else {
                this.router.navigate(['lancrypt', 'connections', 'create-connection']);
              }
            });
        } else {
          this.loadLocalView();
          this.loadConnectionView();
        }
      });
    });
  }

  public importConnection() {
    this.dialog
      .open(DualOptionDialog, {
        width: '350px',
        data: {
          title: this.translateService.instant('connectionImport.startImport'),
          description: this.translateService.instant('connectionImport.startImportText'),
          positiveTitle: this.translateService.instant('common.confirm'),
          negativeTitle: this.translateService.instant('common.cancel'),
        },
      })
      .afterClosed()
      .subscribe(result => {
        if (!result) {
          return;
        }

        const syncAll = this.connectionTreeControl.dataNodes.every(node => this.checklistSelection.isSelected(node.id));
        let userIds: string[] = [];
        let groupIds: string[] = [];
        if (!syncAll) {
          userIds = this.connectionTreeControl.dataNodes
            .filter(node => node.subType === SubFolderType.Users && this.checklistSelection.isSelected(node.id))
            .map(node => node.id);
          groupIds = this.connectionTreeControl.dataNodes
            .filter(
              node =>
                node.subType === SubFolderType.Group &&
                node.level > 0 &&
                (this.checklistSelection.isSelected(node.id) || this.descendantsPartiallySelected(node))
            )
            .map(node => node.id);
        }
        const syncDto = {
          syncAll: syncAll,
          userIds: userIds,
          groupIds: groupIds,
        };
        this.connectionService.startSync(syncDto, this.tenantId, this.connectionId).subscribe({
          next: async (_: any) => {
            this.toastService.showSuccess(
              this.translateService.instant('common.success'),
              this.translateService.instant('connectionImport.startImportSuccess')
            );
            this.router.navigate(['lancrypt', 'connections']);
          },
          error: async (_: any) => {
            this.toastService.showError(
              this.translateService.instant('common.error'),
              this.translateService.instant('connectionImport.error.startingImportFailed')
            );
          },
        });
      });
  }

  public allowImport(): boolean {
    return this.checklistSelection.selected.length > 0 && !this.isSyncInProgress && !this.busyService.isBusy();
  }

  public loadMoreUsers() {
    this.loadMoreUsersClicked = true;
    const usersNode = this.getUsersTreeDto();
    usersNode.children.pop();
    this.loadUsers();
  }

  onCheck(_change: MatCheckboxChange, node: GroupTreeViewFlatNode) {
    this.checklistSelection.toggle(node.id);
    const descendants = this.connectionTreeControl.getDescendants(node);
    this.checklistSelection.isSelected(node.id)
      ? this.checklistSelection.select(...descendants.map(d => d.id))
      : this.checklistSelection.deselect(...descendants.map(d => d.id));
  }

  descendantsAllSelected(node: GroupTreeViewFlatNode): boolean {
    const descendants = this.connectionTreeControl.getDescendants(node);
    return descendants.every(child => this.checklistSelection.isSelected(child.id));
  }

  descendantsPartiallySelected(node: GroupTreeViewFlatNode): boolean {
    const descendants = this.connectionTreeControl.getDescendants(node);
    const result = descendants.some(child => this.checklistSelection.isSelected(child.id));
    return result && !this.descendantsAllSelected(node);
  }

  private loadLocalView() {
    this.groupApiClient.getGroupTreeByTenantId(this.tenantId).subscribe({
      next: async (n: GroupTreeViewDto[]) => {
        if (!n) {
          this.toastService.showInfo(
            this.translateService.instant('common.info'),
            this.translateService.instant('infos.nogroupsFoundForTenant')
          );
          return;
        }

        this.localViewDataSource.data = await this.groupTreeService.buildTreeView(n, GroupTreeTrait.None);

        // Expand all root nodes
        this.localTreeControl.dataNodes.forEach(node => {
          if (node.level === 0) {
            this.localTreeControl.expand(node);
          }
        });
      },
      error: async (_: any) => {
        this.toastService.showError(
          this.translateService.instant('common.error'),
          this.translateService.instant('errors.gettingGroups')
        );
      },
      complete: () => {},
    });
  }

  private loadConnectionView() {
    this.connectionService.getConnectionById(this.tenantId, this.connectionId).subscribe({
      next: (n: IdentityProviderConnectionViewDto) => {
        this.isSyncInProgress = n.activeImportJob;
        this.buildRemoteTree(n);
      },
      error: (_: any) => {
        this.toastService.showError(
          this.translateService.instant('common.error'),
          this.translateService.instant('connectionImport.error.gettingGroupsAndUsersFailed')
        );
      },
      complete: () => {},
    });
  }

  private buildRemoteTree(connection: IdentityProviderConnectionViewDto) {
    this.connectionService.loadGroups(this.tenantId, connection.id).subscribe({
      next: async (groups: GroupTreeViewDto[]) => {
        const root: GroupTreeViewDto = {
          id: connection.id,
          name: connection.name,
          description: '',
          root: true,
          children: groups,
          parents: [],
          treeHierarchyDirection: GroupTreeViewDto.TreeHierarchyDirectionEnum.DESCENDANTS,
          syncedGroup: true,
          identityProviderConnectionId: connection.id,
        };

        this.connectionDataSource.data = await this.groupTreeService.buildTreeView([root], GroupTreeTrait.ShowUsers);

        this.connectionTreeControl.expand(this.connectionTreeControl.dataNodes[0]);

        this.skipToken = undefined;
        this.loadUsers();
      },
      error: async (e: any) => {
        this.toastService.showError(
          this.translateService.instant('common.error'),
          this.translateService.instant('errors.gettingGroups')
        );
        this.handleError(e);
      },
      complete: () => {},
    });
  }

  private loadUsers() {
    this.connectionService.loadUsers(this.tenantId, this.connectionId, this.userPageSize, this.skipToken).subscribe({
      next: async (dto: IdentityProviderConnectionUsersDto) => {
        this.skipToken = dto.skipToken;
        const users = dto.users;

        this.addUsersToUsersNode(users);
      },
      error: async (e: any) => {
        this.toastService.showError(
          this.translateService.instant('common.error'),
          this.translateService.instant('errors.gettingUsers')
        );
        this.handleError(e);
      },
      complete: () => {},
    });
  }

  private addUsersToUsersNode(users: UserTreeViewDto[]) {
    const userAsTreeNodes: GroupTreeViewIconDto[] = users.map(user => {
      return {
        id: user.id,
        name: user.displayName,
        icon: 'account_circle',
        subType: SubFolderType.Users,
        groupName: '',
        children: [],
        parents: [],
        description: '',
        userAmount: 0,
        treeHierarchyDirection: GroupTreeViewDto.TreeHierarchyDirectionEnum.DESCENDANTS,
        root: false,
        syncedGroup: true,
        identityProviderConnectionId: '',
      };
    });

    if (this.skipToken) {
      userAsTreeNodes.push({
        id: 'load-more-users',
        name: this.translateService.instant('connectionImport.loadMoreUsers'),
        icon: '',
        subType: SubFolderType.LoadMoreUsers,
        groupName: '',
        children: [],
        parents: [],
        description: '',
        treeHierarchyDirection: GroupTreeViewDto.TreeHierarchyDirectionEnum.DESCENDANTS,
        root: false,
        syncedGroup: true,
        identityProviderConnectionId: '',
      });
    }
    const usersTreeDto = this.getUsersTreeDto();
    usersTreeDto.children = usersTreeDto.children.concat(...userAsTreeNodes);

    // The self assignment is necessary to update the UI beneath the users node in the import tree
    // eslint-disable-next-line no-self-assign
    this.connectionDataSource.data = this.connectionDataSource.data;

    this.connectionTreeControl.expand(this.connectionTreeControl.dataNodes[0]);
    if (this.loadMoreUsersClicked) {
      this.connectionTreeControl.expand(this.getUsersNode());
    }
  }

  private getUsersTreeDto(): GroupTreeViewIconDto {
    const rootNode = this.connectionDataSource.data[0];
    return rootNode.children[rootNode.children.length - 1];
  }

  private getUsersNode(): GroupTreeViewFlatNode {
    let usersNode = this.connectionTreeControl.dataNodes[0];
    for (const dataNode of this.connectionTreeControl.dataNodes) {
      if (dataNode.level === 1) {
        usersNode = dataNode;
      }
    }
    return usersNode;
  }

  private handleError(e: any) {
    const errors = e.error?.errors as FieldErrorDto[];
    this.hasLoadingError = true;

    if (!errors || errors.length === 0 || errors[0].field.length === 0) {
      this.loadingErrorMessage = this.translateService.instant('connectionImport.error.gettingGroupsAndUsersFailed');
      return;
    }

    const reason = this.translateService.instant(`identityProviderConnection.error.${errors[0].field}`);
    this.loadingErrorMessage = this.translateService.instant(
      'connectionImport.error.gettingGroupsAndUsersFailedReason',
      {reason: reason}
    );
  }
}
