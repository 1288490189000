import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {ConpalUserUpdateDto, PersonalInfoDto, UserService} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc/';
import {ApiClientFactoryService} from '../../../../services/apiclient-factory.service';
import {JwtHelperService} from '../../../../services/helper/jwt-helper.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastService} from 'src/app/services/toaster.service';
import {FIELD_LENGTH_CONSTRAINTS} from '../../../../shared/lancrypt.constants';
import {UserDataService} from '../../../../services/user-data.service';

@Component({
  selector: 'app-edit-basic-info',
  templateUrl: './edit-basic-info.component.html',
  styleUrls: ['./edit-basic-info.component.scss'],
})
export class EditBasicInfoComponent implements OnInit, OnChanges {
  @Input() personalInfo!: PersonalInfoDto;
  @Output() onFinished = new EventEmitter<PersonalInfoDto>();

  userApiClient: UserService;

  maxLengthConstraints = {
    firstName: FIELD_LENGTH_CONSTRAINTS.firstName,
    lastName: FIELD_LENGTH_CONSTRAINTS.lastName,
  };

  constructor(
    private _formBuilder: FormBuilder,
    private translationService: TranslateService,
    private toastService: ToastService,
    private userDataService: UserDataService,
    private apiClientFactory: ApiClientFactoryService,
    private jwtHelperService: JwtHelperService
  ) {
    this.userApiClient = apiClientFactory.getUserService();
  }

  formGroup = this._formBuilder.group({
    firstName: ['', [Validators.required, Validators.maxLength(this.maxLengthConstraints.firstName)]],
    lastName: ['', [Validators.required, Validators.maxLength(this.maxLengthConstraints.lastName)]],
  });

  ngOnInit(): void {}

  ngOnChanges(_changes: SimpleChanges): void {
    if (this.personalInfo) {
      this.formGroup.patchValue({
        firstName: this.personalInfo.firstName,
        lastName: this.personalInfo.lastName,
      });
    }
  }

  async onSave() {
    if (this.formGroup.valid) {
      const tenantId = await this.jwtHelperService.getTenantIdFromToken();

      const dto: ConpalUserUpdateDto = {
        id: this.personalInfo.id,
        firstName: this.formGroup.controls.firstName.value || '',
        lastName: this.formGroup.controls.lastName.value || '',
        emailAddress: this.personalInfo.email,
        mobilePhoneNumber: this.personalInfo.mobilePhone,
        businessPhoneNumber: this.personalInfo.businessPhone,
      };

      this.userApiClient.updateConpalUser(dto, tenantId).subscribe({
        next: async (_: any) => {
          this.personalInfo.firstName = this.formGroup.controls.firstName.value || '';
          this.personalInfo.lastName = this.formGroup.controls.lastName.value || '';
          this.onFinished.emit(this.personalInfo);
          this.userDataService.userData.next({
            id: this.personalInfo.id,
            firstName: dto.firstName,
            lastName: dto.lastName,
            email: dto.emailAddress,
            tenantId: tenantId,
            languageInfo: this.personalInfo.languageInfo.substring(0, 2),
            regionalFormats: this.personalInfo.regionalFormats.substring(0, 2),
          });
        },
        error: async (_: any) => {
          this.toastService.showError(
            this.translationService.instant('common.error'),
            this.translationService.instant('errors.updatingPersonInfo')
          );
          this.onFinished.emit(this.personalInfo);
        },
        complete: () => {},
      });
    }
  }

  onCancel(): void {
    this.onFinished.emit(this.personalInfo);
  }
}
