import {Component, OnInit} from '@angular/core';
import {LogoutService} from './services/auth/logout.service';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'tenant-root',
  template: '<router-outlet></router-outlet>',
})
export class TenantRootComponent implements OnInit {
  constructor(private logoutService: LogoutService) {}

  ngOnInit(): void {
    this.logoutService.enableAutoLogout().subscribe();
  }
}
