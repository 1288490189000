import {EventEmitter, Injectable} from '@angular/core';
import {KeycloakService} from 'keycloak-angular';
import {environment} from '../../../environments/environment';
import {FeatureFlagService} from '../feature-flag.service';
import {debounceTime, fromEvent, mergeWith} from 'rxjs';
import {map} from 'rxjs/operators';
import {AUTO_LOGOUT_DELAY_MS, AUTO_LOGOUT_WARNING_SHOW_MS} from '../../shared/lancrypt.constants';
import {ToastService} from '../toaster.service';
import {TranslateService} from '@ngx-translate/core';
import {ToastRef} from 'ngx-toastr';
import {DatePipe} from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class LogoutService {
  private clicks$ = fromEvent(document, 'click').pipe(map(() => 'click'));
  private scrolls$ = fromEvent(document, 'scroll').pipe(map(() => 'scroll'));
  private keyDowns$ = fromEvent(document, 'keydown').pipe(map(() => 'keydown'));
  private wheel$ = fromEvent(document, 'wheel').pipe(map(() => 'wheel'));
  private refreshEvents$ = this.clicks$
    .pipe(mergeWith(this.scrolls$, this.keyDowns$, this.wheel$))
    .pipe(debounceTime(300));

  private readonly countDownEventEmitter: EventEmitter<{max: number; cur: number}>;
  private logoutWarningShown = false;

  private activeToast?: ToastRef<any>;
  private worker?: Worker;

  constructor(
    private keycloakService: KeycloakService,
    private featureFlagService: FeatureFlagService,
    private toastService: ToastService,
    private translateService: TranslateService,
    private datePipe: DatePipe
  ) {
    this.countDownEventEmitter = new EventEmitter<{max: number; cur: number}>();
    this.setupWorker();
  }

  private setupWorker() {
    if (typeof Worker !== 'undefined') {
      this.worker = new Worker(new URL('../../worker/autologout-countdown.worker', import.meta.url), {type: 'module'});

      this.worker.onmessage = ({data}) => {
        const {type, time} = data;
        switch (type) {
          case 'tick':
            this.countDownEventEmitter.emit({max: AUTO_LOGOUT_DELAY_MS / 1000, cur: time});
            // Handle tick (e.g., update UI)
            if (this.activeToast) {
              this.activeToast.componentInstance.message = this.translateService.instant('autoLogout.notificationMsg', {
                countdown: this.getCountdownString(time * 1000),
              });
            }

            if (time < AUTO_LOGOUT_WARNING_SHOW_MS / 1000 && !this.logoutWarningShown) {
              this.showLogoutWarning(time * 1000);
              this.logoutWarningShown = true;
            }
            break;
          case 'logout':
            this.logout();
            break;
        }
      };
    }
  }

  startCountdown(timeInSeconds: number) {
    this.resetStatus();
    this.worker?.postMessage({type: 'start', payload: {time: timeInSeconds}});
  }

  resetCountdown(timeInSeconds: number) {
    this.resetStatus();
    this.worker?.postMessage({type: 'reset', payload: {time: timeInSeconds}});
  }

  private resetStatus() {
    this.activeToast?.close();
    this.logoutWarningShown = false;
    this.countDownEventEmitter.emit({max: AUTO_LOGOUT_DELAY_MS / 1000, cur: AUTO_LOGOUT_DELAY_MS / 1000});
  }

  stopCountdown() {
    this.worker?.postMessage({type: 'stop'});
  }

  public logout() {
    this.keycloakService.logout(environment.keycloak.redirectUrl);
    this.featureFlagService.clear();
  }

  public getCountDownEventEmitter() {
    return this.countDownEventEmitter;
  }

  public enableAutoLogout() {
    this.startCountdown(AUTO_LOGOUT_DELAY_MS / 1000);
    return this.refreshEvents$.pipe(
      map(_ => {
        this.resetCountdown(AUTO_LOGOUT_DELAY_MS / 1000);
        this.keycloakService.updateToken(10);
      })
    );
  }

  private showLogoutWarning(timeout: number) {
    this.activeToast = this.toastService.showWarning(
      this.translateService.instant('autoLogout.notificationTitle'),
      this.translateService.instant('autoLogout.notificationMsg', {countdown: this.getCountdownString(timeout)}),
      {timeOut: AUTO_LOGOUT_WARNING_SHOW_MS, disableTimeOut: 'extendedTimeOut'}
    ).toastRef;
  }

  private getCountdownString(timeout: number) {
    return this.datePipe.transform(timeout, 'mm:ss');
  }
}
