import {HttpErrorResponse} from '@angular/common/http';
import {Component, Input, OnChanges, OnInit} from '@angular/core';
import {AbstractControl, FormBuilder, FormControl, ValidationErrors, ValidatorFn, Validators} from '@angular/forms';
import {
  SettingsKeyServerCertificateDto,
  SettingsKeyServerDto,
  SettingsService,
  SettingsViewDto,
} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {TranslateService} from '@ngx-translate/core';
import {ApiClientFactoryService} from 'src/app/services/apiclient-factory.service';
import {ToastService} from 'src/app/services/toaster.service';
import {FIELD_LENGTH_CONSTRAINTS, PEM_HEADER_BEGIN, PEM_HEADER_END} from '../../../../../shared/lancrypt.constants';
import * as x509 from '@peculiar/x509';

@Component({
  selector: 'app-settings-keyserver',
  templateUrl: './settings-keyserver.component.html',
  styleUrls: ['../../lancrypt-settings.component.scss'],
})
export class SettingsKeyserverComponent implements OnInit, OnChanges {
  expanded = false;
  editMode = false;
  @Input() settingsViewDto?: SettingsViewDto;
  @Input() tenantId?: string;
  formGroup;
  certs?: x509.X509Certificate[];

  private settingsApi: SettingsService;

  maxLengthConstraints = {
    hostname: FIELD_LENGTH_CONSTRAINTS.keyServerSettings.hostname,
    username: FIELD_LENGTH_CONSTRAINTS.keyServerSettings.username,
    password: FIELD_LENGTH_CONSTRAINTS.keyServerSettings.password,
  };

  pemMakers = {
    begin: PEM_HEADER_BEGIN,
    end: PEM_HEADER_END,
  };

  constructor(
    private _formBuilder: FormBuilder,
    private apiClientFactory: ApiClientFactoryService,
    private toastService: ToastService,
    private translationService: TranslateService
  ) {
    this.formGroup = this._formBuilder.group({
      hostname: ['', [Validators.required, Validators.maxLength(this.maxLengthConstraints.hostname)]],
      port: new FormControl<number | undefined>(undefined, [
        Validators.pattern('^\\d*$'),
        Validators.min(1),
        Validators.max(65535),
      ]),
      username: ['', [Validators.required, Validators.maxLength(this.maxLengthConstraints.username)]],
      password: ['', [Validators.required, Validators.maxLength(this.maxLengthConstraints.password)]],
      certificate: ['', [SettingsKeyserverComponent.certValidator()]],
    });

    this.settingsApi = apiClientFactory.getSettingsService();
  }

  ngOnInit() {
    this.formGroup.disable();
  }

  ngOnChanges(): void {
    this.updateData();
  }

  private updateData(): void {
    if (!this.settingsViewDto?.keyServer) {
      return;
    }

    this.refreshCerts();
    this.formGroup.patchValue({
      username: this.settingsViewDto.keyServer?.username,
      password: this.settingsViewDto.keyServer?.password,
      hostname: this.settingsViewDto.keyServer?.hostname,
      port: this.settingsViewDto.keyServer?.port,
    });
  }

  private refreshCerts() {
    this.certs = this.settingsViewDto?.keyServer?.certificates?.map(c => this.parsePem(c.value!)!);
  }

  onEdit(): void {
    this.editMode = true;
    this.expanded = true;

    this.formGroup.enable();
  }

  onSave() {
    if (!this.formGroup.valid) {
      return;
    }

    const updateDto: SettingsKeyServerDto = {
      hostname: this.formGroup.controls.hostname.value!,
      port: this.formGroup.controls.port.value!,
      password: this.formGroup.controls.password.value!,
      username: this.formGroup.controls.username.value!,
      certificates: this.settingsViewDto?.keyServer?.certificates || [],
    };

    this.settingsApi.updateKeyServerSettings(updateDto, this.tenantId!).subscribe({
      next: async () => {
        this.toastService.showSuccess(
          this.translationService.instant('settings.saveToast.title'),
          this.translationService.instant('settings.saveToast.successful')
        );
        this.editMode = false;
        this.settingsViewDto!.keyServer = updateDto;
        this.formGroup.controls.certificate.patchValue('');
        this.formGroup.disable();

        this.updateData();
      },
      error: async (e: HttpErrorResponse) => {
        this.toastService.showError(
          this.translationService.instant('common.error'),
          this.translationService.instant('settings.errors.saving', {
            error: e.status,
          })
        );
      },
    });
  }

  onCancel(): void {
    this.updateData();
    this.editMode = false;
    this.formGroup.disable();
  }

  onDelete(idx: number) {
    this.settingsViewDto?.keyServer?.certificates?.splice(idx, 1);
    this.refreshCerts();
  }

  onAdd() {
    const certValue = this.formGroup.controls.certificate.value;

    if (certValue === '' || this.parsePem(certValue!) === undefined) {
      return;
    }

    const cert: SettingsKeyServerCertificateDto = {
      value: certValue!,
    };

    if (this.settingsViewDto?.keyServer?.certificates) {
      this.settingsViewDto?.keyServer?.certificates?.push(cert);
    } else {
      const dto: SettingsKeyServerDto = {
        certificates: [cert],
      };

      this.settingsViewDto!.keyServer = dto;
    }

    this.formGroup.controls.certificate.patchValue('');
    this.formGroup.controls.certificate.setErrors(null);
    this.refreshCerts();
  }

  parsePem(pem: string): x509.X509Certificate | undefined {
    const base64 = pem.replace(PEM_HEADER_BEGIN, '').replace(PEM_HEADER_END, '').replace(/\s/g, '');

    try {
      return new x509.X509Certificate(base64);
    } catch {
      return undefined;
    }
  }

  static certValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const content = control.value as string;
      if (content === '' || (content.includes(PEM_HEADER_BEGIN) && content.includes(PEM_HEADER_END))) {
        return null;
      }

      return {invalidCert: {invalid: true}};
    };
  }
}
