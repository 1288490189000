import {Component, OnInit} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

import {IconSetService} from '@coreui/icons-angular';
import {iconSubset} from './icons/icon-subset';
import {KeycloakEventType, KeycloakService} from 'keycloak-angular';
import {JwtHelperService} from './services/helper/jwt-helper.service';
import {Insights} from './services/insights.service';
import {LogoutService} from './services/auth/logout.service';

// Import and register supported locales
import {registerLocaleData} from '@angular/common';
import en from '@angular/common/locales/en';
import de from '@angular/common/locales/de';

registerLocaleData(en);
registerLocaleData(de);

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'body',
  template: '<router-outlet></router-outlet>',
})
export class AppComponent implements OnInit {
  constructor(
    private insights: Insights,
    private router: Router,
    private iconSetService: IconSetService,
    private keycloakService: KeycloakService,
    private jwtHelperService: JwtHelperService,
    private logoutService: LogoutService
  ) {
    // iconSet singleton
    iconSetService.icons = {...iconSubset};

    if (this.keycloakService.isLoggedIn()) {
      // If user is signed into keycloak, but the token cannot be parsed navigate to 'public' route
      jwtHelperService
        .getUserDataFromToken()
        .then(_ => {})
        .catch(_ => this.router.navigate(['/public']));
    }

    this.keycloakService.keycloakEvents$.subscribe(event => {
      if (event.type === KeycloakEventType.OnAuthRefreshError) {
        this.logoutService.logout();
      }
    });
  }

  ngOnInit(): void {
    this.router.events.subscribe(evt => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
    });
  }
}
