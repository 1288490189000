<div class="lancrypt-layout">
  <mat-sidenav-container class="sidenav-container wrapper d-flex flex-column min-vh-100 bg-light dark:bg-transparent">
    <!--app-navigation-->
    <mat-sidenav #drawer class="sidenav d-print-none" fixedInViewport
                 [attr.role]="(isHandset$ | async) ? 'dialog' : 'navigation'"
                 [mode]="(isHandset$ | async) ? 'over' : 'side'"
                 [opened]="(isHandset$ | async) === false">
      <mat-toolbar class="header-bar header">
        <div class="logo-container">
          <a class="logo" routerLink="/">
            <mat-icon class="logo" svgIcon="company-logo"></mat-icon>
          </a>
        </div>
      </mat-toolbar>
      <mat-nav-list>
        <!-- wrap all the nav items in an accordion panel -->
        <mat-accordion displayMode="flat" class="nav-container">
          <div *ngFor="let navItem of navigation">

            <!-- ordinary nav item without children -->
            <a mat-list-item class="nav-item" *ngIf="navItem.children.length === 0" routerLinkActive="nav-item-selected"
               [routerLink]="navItem.route">
              <div class="nav-item-content">
                <mat-icon class="nav-item-icon">{{ navItem.icon }}</mat-icon>
                <span class="nav-item-text">{{ navItem.title | translate }}</span>
              </div>
            </a>

            <!-- expansion panel for nav item section with children -->
            <mat-expansion-panel #navSection *ngIf="navItem.children.length > 0" class="mat-elevation-z0 nav-section"
                                 [expanded]="navSection.expanded || router.isActive(navItem.route, {paths: 'subset', queryParams: 'subset', fragment: 'ignored', matrixParams: 'ignored'})">
              <mat-expansion-panel-header class="nav-section-header">
                <mat-panel-title class="nav-item-content">
                  <mat-icon class="nav-item-icon">{{ navItem.icon }}</mat-icon>
                  <span class="nav-item-text">{{ navItem.title | translate }}</span>
                </mat-panel-title>
              </mat-expansion-panel-header>

              <!-- loop through all child items -->
              <a mat-list-item *ngFor="let navChild of navItem.children" class="nav-item"
                 [routerLinkActiveOptions]="{exact: true}" routerLinkActive="nav-item-selected"
                 [routerLink]="navChild.route">
                <div class="nav-item-content">
                  <mat-icon class="nav-item-icon">{{ navChild.icon }}</mat-icon>
                  <span class="nav-item-text">{{ navChild.title | translate }}</span>
                </div>
              </a>
            </mat-expansion-panel>
          </div>
        </mat-accordion>
      </mat-nav-list>
    </mat-sidenav>
    <mat-sidenav-content class="sidenav-content">
      <mat-toolbar class="header-bar mb-4 d-print-none header header-sticky header-color">
        <button mat-icon-button type="button" class="nav-menu-button" (click)="drawer.toggle()"
                *ngIf="isHandset$ | async">
          <mat-icon>menu</mat-icon>
        </button>
        <!--app-header-->
        <app-lancrypt-admin-header class="header-menu">
        </app-lancrypt-admin-header>
      </mat-toolbar>

      <!--app-body-->
      <div class="body flex-grow-1 px-3">
        <c-container breakpoint="lg" class="h-auto">
          <router-outlet></router-outlet>
        </c-container>
      </div>
      <!--app footer-->
      <app-lancrypt-admin-footer></app-lancrypt-admin-footer>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
