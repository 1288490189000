import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {UserDataService} from '../../../services/user-data.service';
import {LogoutService} from '../../../services/auth/logout.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {
  initials = '';
  firstname = '';
  lastname = '';
  displayName = '';
  email = '';

  constructor(
    private logoutService: LogoutService,
    private userDataService: UserDataService,
    private router: Router
  ) {
    this.userDataService.userData.subscribe(userData => {
      this.firstname = userData.firstName;
      this.lastname = userData.lastName;
      this.email = userData.email;
      this.displayName = this.renderDisplayName(this.firstname, this.lastname);
      this.initials = this.renderInitials(userData.firstName, userData.lastName);
    });
  }

  ngOnInit() {
    const userData = this.userDataService.userData.value;
    this.firstname = userData.firstName;
    this.lastname = userData.lastName;
    this.email = userData.email;
    this.displayName = this.renderDisplayName(this.firstname, this.lastname);
    this.initials = this.renderInitials(userData.firstName, userData.lastName);
  }

  logout() {
    this.logoutService.logout();
  }

  private renderDisplayName(firstName: string, lastName: string): string {
    // If firstName and lastName together are too long, first try to abbreviate firstName...
    const intermediateName = firstName.length + lastName.length > 20 ? firstName.substring(0, 1) + '.' : firstName;

    // If still too long, additionally ellipsis lastName...
    return (
      intermediateName +
      ' ' +
      (intermediateName.length + lastName.length > 20 ? lastName.substring(0, 18).trim() + '\u2026' : lastName)
    );
  }

  private renderInitials(firstName: string, lastName: string): string {
    return (firstName.substring(0, 1) + lastName.substring(0, 1)).toUpperCase();
  }
}
