import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {
  ConpalUserUpdateLanguageInfoDto,
  PersonalInfoDto,
  UserService,
} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc/';
import {TranslateService} from '@ngx-translate/core';
import {ApiClientFactoryService} from '../../../../services/apiclient-factory.service';
import {JwtHelperService} from '../../../../services/helper/jwt-helper.service';
import {ToastService} from 'src/app/services/toaster.service';
import {TranslationHelperService} from 'src/app/services/helper/translation-helper.service';
import {DateAdapter} from '@angular/material/core';

@Component({
  selector: 'app-edit-language-info',
  templateUrl: './edit-language-info.component.html',
  styleUrls: ['./edit-language-info.component.scss'],
})
export class EditLanguageInfoComponent implements OnInit, OnChanges {
  @Input() personalInfo!: PersonalInfoDto;
  @Output() onFinished = new EventEmitter<PersonalInfoDto>();

  formGroup = this._formBuilder.group({
    preferredLanguage: ['', [Validators.required]],
    regionalFormats: ['', [Validators.required]],
  });

  languages = [
    {
      value: 'en',
      viewValue: this.translateService.instant('languages.en.title'),
    },
    {
      value: 'de',
      viewValue: this.translateService.instant('languages.de.title'),
    },
  ];

  userApiClient: UserService;

  constructor(
    private _formBuilder: FormBuilder,
    private translateService: TranslateService,
    private apiClientFactory: ApiClientFactoryService,
    private jwtHelperService: JwtHelperService,
    private toastService: ToastService,
    private translationHelperService: TranslationHelperService,
    private dateAdapter: DateAdapter<any>
  ) {
    this.userApiClient = apiClientFactory.getUserService();
  }

  ngOnInit(): void {}

  async onSave() {
    const tenantId = await this.jwtHelperService.getTenantIdFromToken();

    const dto: ConpalUserUpdateLanguageInfoDto = {
      id: this.personalInfo.id,
      languageInfo: this.formGroup.controls.preferredLanguage.value || '',
      regionalFormats: this.formGroup.controls.regionalFormats.value || '',
    };

    this.userApiClient.updateLanguageInfo(dto, tenantId).subscribe({
      next: async (_: any) => {
        this.personalInfo.languageInfo = dto.languageInfo.substring(0, 2);
        this.personalInfo.regionalFormats = dto.regionalFormats.substring(0, 2);

        this.translateService.setDefaultLang(this.personalInfo.languageInfo);
        this.translateService.use(this.personalInfo.languageInfo);
        this.translationHelperService.setRegionalLocale(this.personalInfo.regionalFormats);
        this.dateAdapter.setLocale(this.personalInfo.regionalFormats);

        this.onFinished.emit(this.personalInfo);
      },
      error: async (_: any) => {
        this.toastService.showError(
          this.translateService.instant('common.error'),
          this.translateService.instant('errors.updatingLanguageInfo')
        );
        this.onFinished.emit(this.personalInfo);
      },
      complete: () => {},
    });
  }

  onCancel(): void {
    this.onFinished.emit(this.personalInfo);
  }

  ngOnChanges(_changes: SimpleChanges): void {
    if (this.personalInfo) {
      this.formGroup.patchValue({
        preferredLanguage: this.personalInfo.languageInfo.substring(0, 2),
        regionalFormats: this.personalInfo.regionalFormats.substring(0, 2),
      });
    }
  }
}
