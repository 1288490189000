import {Component} from '@angular/core';
import {AssetUpdateComponent} from '../asset-update/asset-update.component';

@Component({
  selector: 'app-asset-update',
  templateUrl: '../asset-common/asset-common.component.html',
  styleUrls: ['../asset-common/asset-common.component.scss'],
})
export class AssetRestoreComponent extends AssetUpdateComponent {
  override isRecycleBin = true;

  override getTitle(): string {
    return this.translationService.instant('assets.restoreAsset');
  }

  override getErrorMessage(): string {
    return this.translationService.instant('assets.errorRestoringAsset');
  }

  override getSuccessMessage(): string {
    return this.translationService.instant('assets.assetRestored');
  }

  override cancel() {
    this.router.navigate(['lancrypt/assets/recycle-bin']);
  }

  override navigateAfterSave() {
    this.router.navigate(['lancrypt/assets/recycle-bin']);
  }

  override getSaveButtonText(): string {
    return this.translationService.instant('buttons.restore');
  }
}
