export const navItems = [
  {
    title: 'menus.dashboard',
    route: '/lancrypt/dashboard',
    icon: 'pie_chart',
    children: [],
  },
  {
    title: 'menus.assets',
    route: '/lancrypt/assets',
    icon: 'widgets',
    children: [
      {
        title: 'menus.activeAssets',
        route: '/lancrypt/assets',
        icon: 'inventory_2',
      },
      {
        title: 'menus.recycleBin',
        route: '/lancrypt/assets/recycle-bin',
        icon: 'recycling',
      },
    ],
  },
  {
    title: 'menus.identities',
    route: '/lancrypt/identities',
    icon: 'people_alt',
    children: [],
  },
  {
    title: 'menus.settings',
    route: '/lancrypt/settings',
    icon: 'settings',
    children: [],
  },
  {
    title: 'menus.auditlog',
    route: '/lancrypt/auditlog',
    icon: 'format_list_bulleted',
    children: [],
  },
  {
    title: 'menus.connections',
    route: '/lancrypt/connections',
    icon: 'badge',
    children: [
      {
        title: 'menus.viewConnections',
        route: '/lancrypt/connections',
        icon: 'lan',
      },
      {
        title: 'menus.importJobs',
        route: '/lancrypt/connections/import-jobs',
        icon: 'pending_actions',
      },
    ],
  },
];
