<!--<c-header class="mb-4 d-print-none" position="sticky">-->
<ng-container>
  <c-container [fluid]="true">
    <c-row class="headline-row">
      <c-col sm="9" xl="10" xs="8" class="header-column">
        <div class="container">
          <div class="item-box">
          </div>
        </div>
      </c-col>
      <c-col sm="3" xl="2" xs="4" class="header-column">
        <div class="header-controls">
          <a mat-icon-button class="header-control" matTooltip="{{ 'whatsNew.whatsNew' | translate }}"
             (click)="openWhatsNewDialog()" [removeIfFeatureIsOff]="'whats-new'" data-testid="whats-new">
            <mat-icon>new_releases</mat-icon>
          </a>
          <a mat-icon-button class="header-control" href="{{'userMenu.helpLink' | translate }}" target="_blank"
             matTooltip="{{ 'userMenu.help' | translate }}">
            <mat-icon>help_outline</mat-icon>
          </a>
          <app-user-menu></app-user-menu>
        </div>
      </c-col>
    </c-row>
  </c-container>
</ng-container>
<!--</c-header>-->
