<div class="asset-common-container create-edit-container">
  <mat-card>
    <mat-card-header>
      <mat-card-title>{{ getTitle() }}</mat-card-title>
      <mat-card-subtitle>
        {{ 'assets.assetDescription' | translate }}
      </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="formGroup" class="form center dense-form-field-2">
        <mat-form-field appearance="fill" class="form-element">
          <mat-label>{{ 'assets.formFields.name' | translate }}</mat-label>
          <input matInput formControlName="name" (input)="onNameChange($event)" required>
          <mat-error *ngIf="formGroup.hasError('required','name')">
            {{ 'assets.errors.nameRequired' | translate }}
          </mat-error>
          <mat-error *ngIf="formGroup.hasError('maxlength','name')">
            {{ 'assets.errors.nameMaxLength' | translate: {maxLength: maxLengthConstraints.name} }}
          </mat-error>
          <mat-error *ngIf="formGroup.hasError('notUnique', 'name')">
            {{ 'assets.errors.nameNotUnique' | translate }}
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="fill" class="form-element">
          <mat-label>{{ 'assets.formFields.location' | translate }}</mat-label>
          <input matInput formControlName="location" (input)="onLocationChange($event)" required/>
          <mat-error *ngIf="formGroup.hasError('required','location')">
            {{ 'assets.errors.locationRequired' | translate }}
          </mat-error>
          <mat-error *ngIf="formGroup.hasError('notUnique', 'location')">
            {{ 'assets.errors.locationNotUnique' | translate }}
          </mat-error>
          <mat-error *ngIf="formGroup.hasError('pattern', 'location')">
            {{ 'assets.errors.locationBadPattern' | translate }}
          </mat-error>
          <button class="placeholder-button" mat-button matSuffix mat-raised-button
                  [matMenuTriggerFor]="menu">
            {{ 'assets.formFields.placeholders' | translate }}
            <mat-icon>arrow_drop_down</mat-icon> <!--iconPositionEnd-->
          </button>
          <mat-menu #menu="matMenu">
            <button (click)="onPlaceholderChange(placeholder.viewValue)" *ngFor="let placeholder of placeholders"
                    mat-menu-item>
              {{ placeholder.viewValue | translate }}
            </button>
          </mat-menu>
        </mat-form-field>

        <mat-label class="asset-form-field-label">{{ 'assets.formFields.access' | translate }}</mat-label>
        <div class="asset-assignment">
          <div class="asset-multiselect">
            <mat-form-field floatLabel="always" subscriptSizing="dynamic">
              <mat-label>{{ 'assets.formFields.groups' | translate }}</mat-label>
              <mat-select ngxLazyMatSelect (infiniteScroll)="loadNextGroupBatch()"
                          formControlName="groupIds"
                          placeholder="{{getAssignedGroupCountString()}}"
                          [complete]="groupPageCounter === groupPageCount"
                          [multiple]="true">
                <mat-select-trigger>
                  {{ getAssignedGroupCountString() }}
                </mat-select-trigger>
                <mat-option>
                  <ngx-mat-select-search [formControl]="groupFilterCtrl"
                                         placeholderLabel="{{ 'common.search' | translate }}"
                                         noEntriesFoundLabel="{{ 'groups.noGroupFound' | translate}}"/>
                </mat-option>
                <mat-option (onSelectionChange)="changeGroupMultiselect($event)"
                            *ngFor="let group of groupObservable | async"
                            value={{group.id}}>{{ group.name }}
                </mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field floatLabel="always" subscriptSizing="dynamic">
              <mat-label>{{ 'assets.formFields.users' | translate }}</mat-label>
              <mat-select ngxLazyMatSelect (infiniteScroll)="loadNextUserBatch()"
                          formControlName="userIds"
                          placeholder="{{getAssignedUsersCountString()}}"
                          [complete]="userPageCounter === userPageCount"
                          [multiple]="true">
                <mat-select-trigger>
                  {{ getAssignedUsersCountString() }}
                </mat-select-trigger>
                <mat-option>
                  <ngx-mat-select-search [formControl]="userFilterCtrl"
                                         placeholderLabel="{{ 'common.search' | translate }}"
                                         noEntriesFoundLabel="{{ 'users.noUserFound' | translate}}"/>
                </mat-option>
                <mat-option (onSelectionChange)="changeUserMultiselect($event)"
                            *ngFor="let user of userObservable | async"
                            value={{user.id}}>{{ user.displayName }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
          <div *ngIf="groupsAndUsersHint()" class="hint">
            <mat-hint class="mat-mdc-form-field-subscript-wrapper">{{ 'assets.groupsAndUsersHint' | translate }}
            </mat-hint>
          </div>
        </div>
        <mat-label class="asset-form-field-label"
                   id="asset-type-radio-group-label">{{ 'assets.formFields.type' | translate }}
        </mat-label>
        <mat-radio-group aria-labelledby="asset-type-radio-group-label"
                         class="asset-radio-group"
                         formControlName="assetType">
          <mat-radio-button color="warn" *ngFor="let type of types" [value]="type">
            <div class="asset-type-label">
              {{ 'assets.type.' + type.toString().toLowerCase() | translate }}
            </div>
            <div>
              <mat-card-subtitle>{{ 'assets.type.' + type.toString().toLowerCase() + 'AccessHint' | translate }}</mat-card-subtitle>
            </div>
            <div>
              <mat-card-subtitle>{{ 'assets.type.' + type.toString().toLowerCase() + 'DataHint' | translate }}</mat-card-subtitle>
            </div>
          </mat-radio-button>
        </mat-radio-group>
      </form>
    </mat-card-content>
    <mat-card-actions class="control-elements">
      <button mat-raised-button color="lancrypt-primary" (click)="createOrUpdateAsset()" disableDuringRequest>
        {{ getSaveButtonText() }}
      </button>
      <button mat-raised-button class="" (click)="cancel()" disableDuringRequest>
        {{ 'buttons.cancel' | translate }}
      </button>
    </mat-card-actions>
    <mat-card-footer>
      <mat-progress-bar mode="indeterminate" *onlyShowWhenBusy></mat-progress-bar>
    </mat-card-footer>
  </mat-card>
</div>
