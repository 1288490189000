import {Injectable} from '@angular/core';
import {ToastrService} from 'ngx-toastr';
import {IndividualConfig} from 'ngx-toastr/toastr/toastr-config';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(private toastr: ToastrService) {}

  showSuccess(title: string, message: string, override?: Partial<IndividualConfig>) {
    return this.toastr.success(message, title, override);
  }

  showError(title: string, message: string, override?: Partial<IndividualConfig>) {
    return this.toastr.error(message, title, override);
  }

  showInfo(title: string, message: string, override?: Partial<IndividualConfig>) {
    return this.toastr.info(message, title, override);
  }

  showWarning(title: string, message: string, override?: Partial<IndividualConfig>) {
    return this.toastr.warning(message, title, override);
  }
}
