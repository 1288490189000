import {Component, OnInit} from '@angular/core';
import {HttpErrorResponse} from '@angular/common/http';
import {
  AssetCreateDto,
  AssetService,
  AssetViewDto,
  FieldErrorDto,
  GroupSearchRequestDto,
  GroupSelectDto,
  GroupSelectPageResultDto,
  GroupService,
  UserPageResultDto,
  UserSearchRequestDto,
  UserService,
  UserTreeViewDto,
} from '@lancrypt/lc-portal-fe-cmp-typescript/build/out-tsc';
import {
  DEFAULT_DEBOUNCE_TIME,
  DEFAULT_DROPDOWN_PAGE_SIZE,
  FIELD_LENGTH_CONSTRAINTS,
} from '../../../../../shared/lancrypt.constants';
import {DropDownItem} from '../../../../../dtos/lancrypt/dropDownItem';
import {AbstractControl, FormBuilder, FormControl, ValidationErrors, Validators} from '@angular/forms';
import {ApiClientFactoryService} from '../../../../../services/apiclient-factory.service';
import {JwtHelperService} from '../../../../../services/helper/jwt-helper.service';
import {ToastService} from '../../../../../services/toaster.service';
import {TranslateService} from '@ngx-translate/core';
import {Router} from '@angular/router';
import {BehaviorSubject, debounceTime, distinctUntilChanged, Observable} from 'rxjs';
import {scan} from 'rxjs/operators';
import {MatOptionSelectionChange} from '@angular/material/core';
import {Placeholder, PlaceholderService} from '../../placeholder.service';
import {CustomValidators} from '../../../../../shared/components/validators/custom-validators';

@Component({
  selector: 'app-asset-common',
  templateUrl: './asset-common.component.html',
  styleUrls: ['./asset-common.component.scss'],
})
export class AssetCommonComponent implements OnInit {
  protected assetService: AssetService;
  protected groupService: GroupService;
  protected userService: UserService;

  private tenantId!: string;
  placeholders: Placeholder[] = this.placeholderService.values;

  types: AssetCreateDto.TypeEnum[] = ['PRIVATE', 'SHARED', 'PUBLIC'];

  maxLengthConstraints = {
    name: FIELD_LENGTH_CONSTRAINTS.asset.name,
    type: FIELD_LENGTH_CONSTRAINTS.asset.type,
  };

  groups: DropDownItem[] = [];

  nameUnique = true;
  locationUnique = true;
  locationRegex = '^([a-zA-Z]:(|\\\\[^\x00-\x1F<>:"\\/|]*))$|^([^\x00-\x1F<>:"\\/|]+)$';

  formGroup = this._formBuilder.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(this.maxLengthConstraints.name), this.nameUniqueValidator.bind(this)],
    ],
    location: ['', [Validators.required, this.locationUniqueValidator.bind(this)]],
    assetType: [AssetCreateDto.TypeEnum.SHARED, [Validators.required]],
    userIds: new FormControl<string[]>(['']),
    groupIds: new FormControl<string[]>(['']),
  });

  userPageCounter = 0;
  userPageCount = 0;

  userObservable: Observable<UserTreeViewDto[]>;
  userOptions!: BehaviorSubject<UserTreeViewDto[]>;

  userIdNameMap: Map<string, string> = new Map<string, string>();
  userFilterCtrl: FormControl = new FormControl();
  userFilter = '';
  assignedUsers: string[] = [];

  groupPageCounter = 0;
  groupPageCount = 0;

  groupObservable: Observable<GroupSelectDto[]>;
  groupOptions!: BehaviorSubject<GroupSelectDto[]>;

  groupIdNameMap: Map<string, string> = new Map<string, string>();
  groupFilterCtrl: FormControl = new FormControl();
  groupFilter = '';
  assignedGroups: string[] = [];

  constructor(
    private _formBuilder: FormBuilder,
    apiClientFactory: ApiClientFactoryService,
    protected jwtHelperService: JwtHelperService,
    protected toastService: ToastService,
    protected translationService: TranslateService,
    protected router: Router,
    protected placeholderService: PlaceholderService
  ) {
    this.assetService = apiClientFactory.getAssetService();
    this.groupService = apiClientFactory.getGroupService();
    this.userService = apiClientFactory.getUserService();

    this.userObservable = this.getUpdatedUserObservable();
    this.groupObservable = this.getUpdatedGroupObservable();
  }

  private getUpdatedUserObservable(): Observable<UserTreeViewDto[]> {
    this.userOptions = new BehaviorSubject<UserTreeViewDto[]>([]);
    return this.userOptions.asObservable().pipe(
      scan((acc, cur) => {
        if (!acc || !cur) return [];
        return [...acc, ...cur];
      })
    );
  }

  private getUpdatedGroupObservable(): Observable<GroupSelectDto[]> {
    this.groupOptions = new BehaviorSubject<GroupSelectDto[]>([]);
    return this.groupOptions.asObservable().pipe(
      scan((acc, cur) => {
        if (!acc || !cur) return [];
        return [...acc, ...cur];
      })
    );
  }

  protected buildDto(): any {
    return undefined;
  }

  loadNextUserBatch() {
    const asset = this.getAsset();
    const newSearchDto: UserSearchRequestDto = {
      pageRequest: {
        pageNumber: this.userPageCounter,
        pageSize: DEFAULT_DROPDOWN_PAGE_SIZE,
      },
      searchString: this.userFilter,
      sortOrder: {
        descending: false,
        column: 'name',
      },
    };

    this.userService.getUsersAssetAssignmentSorted(newSearchDto, this.tenantId, asset?.id, false).subscribe({
      next: (n: UserPageResultDto) => {
        this.formGroup.controls.userIds.setValue(this.assignedUsers);
        this.addUsersToMap(n.content);
        this.userOptions.next(n.content);
        this.userPageCount = n.totalPages;
        this.userPageCounter += 1;
      },
    });
  }

  loadNextGroupBatch() {
    const asset = this.getAsset();
    const newSearchDto: GroupSearchRequestDto = {
      pageRequest: {
        pageNumber: this.groupPageCounter,
        pageSize: DEFAULT_DROPDOWN_PAGE_SIZE,
      },
      searchString: this.groupFilter,
      sortOrder: {
        descending: false,
        column: 'name',
      },
    };

    this.groupService.availableGroupsForTenantAssetAssignmentSorted(newSearchDto, this.tenantId, asset?.id).subscribe({
      next: (n: GroupSelectPageResultDto) => {
        this.formGroup.controls.groupIds.setValue(this.assignedGroups);
        this.addGroupsToMap(n.content);
        this.groupOptions.next(n.content);
        this.groupPageCount = n.totalPages;
        this.groupPageCounter += 1;
      },
    });
  }

  ngOnInit(): void {
    const asset = this.getAsset();
    if (asset !== undefined) {
      this.assignedUsers = asset.grantAccessUsers;
      this.assignedGroups = asset.grantAccessGroups;
      this.formGroup.controls.name.setValue(asset!.name);
      this.formGroup.controls.location.setValue(
        this.placeholderService.translateToLocalizedPlaceholders(asset!.location)
      );
      this.formGroup.controls.assetType.setValue(asset!.type);
      this.formGroup.controls.userIds.setValue(this.assignedUsers);
      this.formGroup.controls.groupIds.setValue(this.assignedGroups);
    }

    this.userFilterCtrl.valueChanges
      .pipe(debounceTime(DEFAULT_DEBOUNCE_TIME), distinctUntilChanged())
      .subscribe(value => {
        if (this.userFilter !== value) {
          this.userFilter = value;
          this.userPageCounter = 0;
          this.userObservable = this.getUpdatedUserObservable();
          this.loadNextUserBatch();
        }
      });

    this.groupFilterCtrl.valueChanges
      .pipe(debounceTime(DEFAULT_DEBOUNCE_TIME), distinctUntilChanged())
      .subscribe(value => {
        if (this.groupFilter !== value) {
          this.groupFilter = value;
          this.groupPageCounter = 0;
          this.groupObservable = this.getUpdatedGroupObservable();
          this.loadNextGroupBatch();
        }
      });

    this.jwtHelperService.getTenantIdFromToken().then(tenantId => {
      this.tenantId = tenantId;

      this.loadNextUserBatch();
      this.loadNextGroupBatch();
    });

    CustomValidators.createIgnorePrefixPatternValidator(
      this.placeholders.map(placeholder => this.translationService.instant(placeholder.viewValue)),
      this.locationRegex
    );
  }

  getAssignedUsersCountString(): string {
    const count = this.assignedUsers.length;
    let template = 'assets.users.assignedMulti';
    if (count === 1) {
      template = 'assets.users.assignedSingle';
    }
    return this.translationService.instant(template, {count: count});
  }

  getAssignedGroupCountString(): string {
    const count = this.assignedGroups.length;
    let template = 'assets.groups.assignedMulti';
    if (count === 1) {
      template = 'assets.groups.assignedSingle';
    }
    return this.translationService.instant(template, {count: count});
  }

  private nameUniqueValidator(_: AbstractControl): ValidationErrors | null {
    return this?.nameUnique ? null : {notUnique: true};
  }

  private locationUniqueValidator(_: AbstractControl): ValidationErrors | null {
    return this?.locationUnique ? null : {notUnique: true};
  }

  onPlaceholderChange(value: string) {
    this.formGroup.controls.location.setValue(this.translationService.instant(value));
  }

  onNameChange(_: Event): void {
    this.nameUnique = true;
    this.formGroup.controls.name.updateValueAndValidity();
  }

  onLocationChange(_: Event): void {
    this.locationUnique = true;
    this.formGroup.controls.location.updateValueAndValidity();
  }

  createOrUpdateAsset() {
    if (!this.formGroup.valid) {
      this.formGroup.markAllAsTouched();
      return;
    }

    this.callService(this.tenantId, this.buildDto()).subscribe({
      next: _ => {},
      error: async (e: HttpErrorResponse) => {
        const errors = e.error.errors;
        if (errors !== undefined) {
          errors.forEach((x: FieldErrorDto) => {
            if (x.field === 'name') {
              this.nameUnique = false;
              this.formGroup.controls.name.updateValueAndValidity();
            }
            if (x.field === 'location') {
              this.locationUnique = false;
              this.formGroup.controls.location.updateValueAndValidity();
            }
            this.formGroup.markAllAsTouched(); //Workaround for highlighting non uniqueness when restoring an asset
          });
        }

        this.toastService.showError(this.translationService.instant('common.error'), this.getErrorMessage());
      },
      complete: () => {
        this.navigateAfterSave();
        this.toastService.showSuccess(this.translationService.instant('common.success'), this.getSuccessMessage());
      },
    });
  }

  cancel() {
    this.router.navigate(['lancrypt/assets']);
  }

  navigateAfterSave() {
    this.router.navigate(['lancrypt', 'assets']);
  }

  public getTitle(): string {
    return '';
  }

  public getSaveButtonText(): string {
    return this.translationService.instant('buttons.save');
  }

  public getErrorMessage(): string {
    return '';
  }

  public getSuccessMessage(): string {
    return '';
  }

  protected getAsset(): AssetViewDto | undefined {
    return undefined;
  }

  public groupsAndUsersHint(): boolean {
    return true;
  }

  protected callService(_tenantId: string, _dto: any): Observable<any> {
    return new Observable<any>();
  }

  private addUsersToMap(users: UserTreeViewDto[]) {
    for (const user of users) {
      this.userIdNameMap.set(user.id, user.displayName);
    }
  }

  private addGroupsToMap(groups: GroupSelectDto[]) {
    for (const group of groups) {
      this.groupIdNameMap.set(group.id, group.name);
    }
  }

  changeGroupMultiselect(event: MatOptionSelectionChange) {
    if (event.isUserInput) {
      if (event.source.selected) {
        this.assignedGroups.push(event.source.value);
      } else {
        const index = this.assignedGroups.indexOf(event.source.value);
        if (index > -1) {
          this.assignedGroups.splice(index, 1);
        }
      }
    }
  }

  changeUserMultiselect(event: MatOptionSelectionChange) {
    if (event.isUserInput) {
      if (event.source.selected) {
        this.assignedUsers.push(event.source.value);
      } else {
        const index = this.assignedUsers.indexOf(event.source.value);
        if (index > -1) {
          this.assignedUsers.splice(index, 1);
        }
      }
    }
  }
}
