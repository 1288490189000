<div class="lancrypt-assets-container">
  <div class="lancrypt-assets-header">
    <div class="headline-container">
      <h1>{{ getTitle() }}</h1>
    </div>
  </div>

  <mat-divider class="headline-divider"></mat-divider>
  <mat-card>
    <mat-card-header class="header dense-form-field-2">
      <mat-form-field class="asset-filter">
        <mat-label>{{ 'assets.filter' | translate }}</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="{{'assets.search' | translate}}" #input>
      </mat-form-field>

      <div class="control-elements">
        <button *ngIf="!isRecycleBin()" matTooltip="{{ 'buttons.addAsset' | translate }}" mat-mini-fab
                color="lancrypt-primary"
                (click)="newAsset()" disableDuringRequest>
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </mat-card-header>

    <mat-card-content>
      <table mat-table [dataSource]="dataSource" matSort>
        <!-- Name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'assets.columns.asset' | translate }}</th>
          <td mat-cell *matCellDef="let asset">{{ asset.name }}</td>
        </ng-container>

        <!-- Location Column-->
        <ng-container matColumnDef="location">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'assets.columns.location' | translate }}</th>
          <td mat-cell *matCellDef="let asset">{{ asset.location }}</td>
        </ng-container>

        <!-- Type Column-->
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'assets.columns.type' | translate }}</th>
          <td mat-cell *matCellDef="let asset">{{ 'assets.type.' + asset.type.toLowerCase() | translate }}</td>
        </ng-container>

        <!-- Access Column-->
        <ng-container matColumnDef="access">
          <th mat-header-cell *matHeaderCellDef>{{ 'assets.columns.access' | translate }}</th>
          <td mat-cell *matCellDef="let asset">{{ renderAccessGrantedTo(asset) }}</td>
        </ng-container>

        <!-- delete column -->
        <ng-container matColumnDef="delete">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let asset">
            <button mat-icon-button (click)="callAssetAction(asset.id); $event.stopPropagation()" disableDuringRequest
                    matTooltip="{{ getAssetActionTooltip() }}">
              <mat-icon>{{ getAssetActionIcon() }}</mat-icon>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="getColumnsToDisplay()"></tr>
        <tr mat-row disableDuringRequest
            (click)=editAsset(row)
            *matRowDef="let row; columns: getColumnsToDisplay();"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell mat-cell-empty" [attr.colspan]="getColumnsToDisplay().length">
            {{ 'assets.noAssetsFound' | translate }}
          </td>
        </tr>
      </table>
      <mat-paginator #paginator [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" [length]="totalRows"
                     [pageIndex]="currentPage" (page)="pageChanged($event)"
                     showFirstLastButtons></mat-paginator>

    </mat-card-content>
    <mat-card-footer>
      <mat-progress-bar mode="indeterminate" *onlyShowWhenBusy></mat-progress-bar>
    </mat-card-footer>
  </mat-card>
</div>


